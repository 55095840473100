import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import { Button, Stack } from "@mui/material";
import { validateGroupAndPIN } from '../../Firebase/Firebase';
import './GroupAndPINValidation.css';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../SelectAvailability/SelectAvailability";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';


export default class GroupAndPINValidation extends Component {
    constructor() {
        super();
        this.state = {
            groupID: "",
            groupPIN: "",
            inError: false
        };
    }

    render() {
        return <div className="groupAndPINValidationContainer">
            <ThemeProvider theme={theme}>
                    <Grid container className="groupAndPINValidationGrid" spacing={2}>
                        <Grid item xs={8} sm={8} md={8} lg={7}>
                            <img src="./logo.svg" alt="logo" width={"100%"} />
                        </Grid>
                        <Divider orientation="vertical" flexItem margin={"5%"} color="#ffffff" />
                        <Grid item xs={8} sm={8} md={8} lg={3}>
                            <Stack spacing={1} textAlign={"center"}>
                                <TextField id="groupID" className="inputField"  label="Group ID" variant="filled" onChange={this.onGroupIDChange}/>
                                <TextField id="groupPIN" type="number" className="inputField"  label="PIN" variant="filled" onChange={this.onGroupPINChange} />
                                <Button className="inputField" variant="contained" onClick={this.validate}>Enter group</Button>
                                <Button className="inputField" variant="contained" onClick={this.switchToCreateGroupPage}>Create a group</Button>
                                <h4 id="errorMessage"/>
                                {this.state.inError && this.getErrorMessage()}
                            </Stack>
                        </Grid>
                    </Grid>
            </ThemeProvider>
        </div>
    }

    getErrorMessage = () => {
        return (<Alert severity="error">The Group ID or PIN is invalid.</Alert>);
    };

    onGroupIDChange = (e) => {
        this.setState({groupID: e.target.value});
    }

    onGroupPINChange = (e) => {
        this.setState({groupPIN: e.target.value});
    }
 
    validate = () => {
        if (this.state.groupID === "" || this.groupPIN === "") {
            this.setState({inError: true});
            return;
        }

        validateGroupAndPIN(this.state.groupID, this.state.groupPIN).then((result) => {
            switch(result) {
                case "PINInvalid":
                    //document.getElementById("errorMessage").textContent="PIN Invalid";
                    this.setState({inError: true});
                    return;
                case "GroupIDInvalid":
                    //document.getElementById("errorMessage").textContent="Group ID Invalid";
                    this.setState({inError: true});
                    return;
            }
            this.props.onSubmit("SelectAvailability", {groupID: result});
        });
    };

    switchToCreateGroupPage = () => {
        this.props.onSubmit("CreateGroup");
    };
}
