import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import { Button, Stack } from "@mui/material";
import {saveGroupToDB} from '../../Firebase/Firebase';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../SelectAvailability/SelectAvailability";
import Grid from '@mui/material/Grid';
import './CreateGroup.css';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FaceIcon from '@mui/icons-material/Face';
import Alert from '@mui/material/Alert';

export default class CreateGroup extends Component {
    constructor() {
        super();
        this.state = {
            groupName: "",
            groupPIN: "",
            groupMembers: [],
            currentGroupMemberName: "",
            successDialogOpen: false,
            groupID: "",
            inError: false,
            errorType: ""
        };
    }

    render() {
        return <div className="createGroupContainer">
            <ThemeProvider theme={theme}>
                <Grid container spacing={1} className="createGroupGrid">
                    <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                        <h4 className="bannerSubheader">Create your group.</h4>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={3}>
                            <Stack spacing={2} margin={"5%"}>
                                <TextField required id="groupName" label="Group Name" variant="filled" onChange={this.onGroupNameChange}/>
                                <TextField required id="groupPIN" type="number" label="PIN" variant="filled" onChange={this.onGroupPINChange} />
                            </Stack>
                    </Grid>
                    <Divider orientation="vertical" flexItem margin={"5%"} color="#ffffff" />
                    <Grid item xs={8} sm={8} md={8} lg={3}>
                            <Stack spacing={2} margin={"5%"}>
                                <Divider color="#ffffff">
                                    <Chip label="Group Members" size="large" />
                                </Divider>
                                <TextField id="groupMemberName" label="Group Member Name" variant="filled" onKeyDown={this.onKeyPressOnMemberTextField} onChange={this.onCurrentGroupMemberNameChange} />
                                <Grid container spacing={1} className="createGroupGrid">
                                        {this.displayableMemberList()}
                                        </Grid>
                                <Button variant="contained" onClick={this.addMember}>Add Member</Button>
                                <h4 id="instructionMessage" className="instructionMessage">Select a name to remove them from the group.</h4>
                            </Stack>
                    </Grid>
                    <Divider orientation="vertical" flexItem margin={"5%"} color="#ffffff" />
                    <Grid item xs={8} sm={6} md={6} lg={3}>
                        <Stack spacing={2} margin={"5%"}>
                            <Button variant="contained" onClick={this.saveData}>Submit</Button>
                        </Stack>
                    </Grid>
                    <Grid xs={8} margin={"1%"}>
                        {this.state.inError && this.getErrorMessage()}
                    </Grid>
                </Grid>
                {this.state.successDialogOpen && this.getSuccessDialog()}
            </ThemeProvider>
        </div>
    }

    onKeyPressOnMemberTextField = (e) => {
        if (e.keyCode === 13) {
            this.addMember();
        }
    };

    getErrorMessage = () => {
        switch (this.state.errorType) {
            case "ValidationFailure":
                return (<Alert severity="error">Please ensure all fields are filled in and there are atleast 2 members. Member names must be unique and not empty.</Alert>);
            case "SaveFailure":
                return (<Alert severity="error">We encountered an error. Please try submitting again.</Alert>);
        }
        return (<Alert severity="error">Something went wrong. Please try submitting again.</Alert>);
    };

    getSuccessDialog = () => {
        return (<Dialog
        open={this.state.successDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your group has been created."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Take note of your group ID and PIN as you will need these to login:
            <br/>Group ID: {this.state.groupID}
            <br/>PIN: {this.state.groupPIN}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.onSuccessDialogClose}>Continue</Button>
        </DialogActions>
      </Dialog>);
    }

    onSuccessDialogClose = () => {
        this.setState({successDialogOpen: false});
        this.props.onSubmit("GroupAndPINValidation");
    }

    onGroupNameChange = (e) => {
        this.setState({groupName: e.target.value});
    };

    onGroupPINChange = (e) => {
        this.setState({groupPIN: e.target.value});
    };

    onCurrentGroupMemberNameChange = (e) => {
        this.setState({currentGroupMemberName: e.target.value});
    };

    addMember = () => {
        const newMember = {
            name: this.state.currentGroupMemberName,
            availability: []
        };

        if (newMember.name == "" || this.state.groupMembers.filter(member => member.name == newMember.name).length == 1) {
            this.setState({inError: true, errorType: "ValidationFailure"});
            return;
        }

        document.getElementById("groupMemberName").value = "";

        this.setState({groupMembers: [...this.state.groupMembers, newMember], currentGroupMemberName: ""});
    };

    removeMember = (name) => {
        this.setState({groupMembers: this.state.groupMembers.filter(member => member.name != name)});
    }

    displayableMemberList = () => {
        let rows = [];
        for (var i = 0; i < this.state.groupMembers.length; i++) {
            const name = this.state.groupMembers[i].name;
            rows.push(
                <Grid xs={8} sm={8} md={4} lg={4} margin={"1%"} textAlign={"center"}>
                    <Chip icon={<FaceIcon />} label={name} onDelete={() => this.removeMember(name)}  />
                </Grid>
            )
        }
        return rows;
    };
 
    saveData = () => {
        if (!this.validate()) {
            return;
        }
        saveGroupToDB(this.state.groupName, this.state.groupPIN, this.state.groupMembers).then((groupID) => {
            if (groupID === null) {
                this.setState({inError: true, errorType: "SaveFailure"});
                return;
            }
            this.setState({successDialogOpen: true, groupID: groupID});
        });        
    };

    validate = () => {
        if (this.state.groupName === "" || this.state.groupPIN === "" || this.state.groupMembers.length < 2) {
            this.setState({inError: true, errorType: "ValidationFailure"});
            return false;
        }
        return true;
    }
}