import React, {Component} from 'react';
import CreateGroup from "./CreateGroup/CreateGroup";
import SelectAvailability from './SelectAvailability/SelectAvailability';
import GroupAndPINValidation from './GroupAndPINValidation/GroupAndPINValidation';

export default class Container extends Component {
    constructor() {
        super();
        this.state = {
            currentPage: "",
            pageProps: {}
        };
    }

    render() {
        switch(this.state.currentPage) {
            case "CreateGroup":
                return <CreateGroup onSubmit={this.switchPage}/>
            case "SelectAvailability":
                return <SelectAvailability groupID={this.state.pageProps.groupID}/>
        };
        return <GroupAndPINValidation onSubmit={this.switchPage}/>;
    }

    switchPage = (page, props) => {
        this.setState({currentPage: page, pageProps: props});
    };
}
