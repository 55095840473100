import DatePicker from "../../DatePicker/DatePicker";
import React, {Component} from 'react';
import { appendAvailability, loadGroupData } from "../../Firebase/Firebase";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import './SelectAvailability.css';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ToggleButton from '@mui/material/ToggleButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#cddc39' : '#308fe8',
    },
  }));

export const theme = createTheme({
    palette: {
        primary: lime,
        secondary: purple,
      },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            outlineColor: "white",
            InputLabel:"white"
          },
          filled: {
            color: "white",
            InputLabel: "white",
            accentColor: "white",
            outlineColor: "white"        
          }
        },
      },
      MuiFilledInput: {
        styleOverrides: {
            root: {
                color: "white"
            }
        }
      },
      MuiButton: {
        styleOverrides: {
            root: {
                fontWeight:"bold",
                backgroundColor: "purple",
                color: "white" 
            },
        }
      },
      MuiChip: {
        styleOverrides: {
            root: {
                color: "white"
            }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
            root: {
                color: "white",
                fontWeight:"bold",
                selected: {
                    color: "white"
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                    color: "white",
                  }
            }
        }
      }
    },
  });

export default class SelectAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupData: {},
            currentUserSelected: "",
            showCommonDates: false,
            winningDate: undefined,
            groupMemberCount: 0,
            numberOfSubmissions: 0,
            groupName: "",
            fetchingData: true
        };
        this.fetchPageData();
    }
    

    render() {
        if (this.state.fetchingData) {
            return;
        }

        const availability = this.state.showCommonDates ? [] : this.getSelectedMembersDates();
        return <div className="selectAvailabilityContainer">
            <ThemeProvider theme={theme}>
            <Grid container className="selectAvailabilityGrid">
                <Grid xs={8} sm={8} md={8} lg={4}>
                        <Stack spacing={2}>
                            <h1 id="groupName" className="selectAvailabilityHeader">{this.state.groupData.groupName}</h1>
                            {this.getProgressBar()}
                            <h3 id="numberOfMembers" className="selectAvailabilityMemberCount">{this.getNumberOfMembersCompleted()}/{this.state.groupData.groupMembers.length} completed </h3>
                            {this.getStats()}
                        </Stack>
                </Grid>
                <Grid xs={8} sm={8} md={8} lg={3} margin={"30px"}>
                    <Stack spacing={2}>
                        <DatePicker onChange={this.onDateChange} availability={availability} commonAvailabilities={this.getCommonAvailability()}/>
                        <ThemeProvider theme={theme}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel id="demo-simple-select-label">Group Member</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.currentUserSelected}
                                    label="Group Member"
                                    onChange={this.handleGroupMemberChange}
                                >
                                    {this.getGroupMembers()}
                                </Select>
                            </FormControl>
                            <Button variant="contained" size="medium" onClick={this.submitAvailability}>Submit Availability</Button>
                            <ToggleButton value="Show Common Dates" selected={this.state.showCommonDates} onChange={this.showHideCommonDates}>Show Common Dates</ToggleButton>
                            <p id="submitAvailabilityError" className="selectAvailabilityMemberCount"></p>
                        </ThemeProvider>
                    </Stack>
                </Grid>
            </Grid>
            </ThemeProvider>
        </div>
    }

    getSelectedMembersDates = () => {
        const availability = this.state.groupData.groupMembers.filter(member => member.name === this.state.currentUserSelected)[0].availability;
        return availability ? availability : [] ;
    };

    getStats = () => {
        if (this.getNumberOfMembersCompleted() == 0) { 
            return;
        }
        const attendance = this.getAttendance();
        const displayableMajorityWinner = this.getDateStringFromTimestamp(this.getBestDateForMajority());
        
        return (
            <Stack spacing={2} margin={"5%"}>
                {attendance.whoCanMakeIt.length > 0 && <Grid container spacing={1} justifyContent={"center"} className="selectAvailabilityStatsGrid">
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Divider color="#ffffff">
                            <Chip label="Can Attend" size="large" />
                        </Divider>
                    </Grid>
                    {this.displayableWhoCanMakeIt(attendance.whoCanMakeIt)}
                </Grid>}
                {attendance.whoCannotMakeIt.length > 0 && <Grid container spacing={1} justifyContent={"center"} className="selectAvailabilityStatsGrid">
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Divider color="#ffffff">
                            <Chip label="Cannot Attend" size="large" />
                        </Divider>
                    </Grid>
                    {this.displayableWhoCannotMakeIt(attendance.whoCannotMakeIt)}
                </Grid>}
                {attendance.yetToRespond.length > 0 && <Grid container spacing={1} justifyContent={"center"} className="selectAvailabilityStatsGrid">
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Divider color="#ffffff">
                            <Chip label="Yet to Respond" size="large" />
                        </Divider>
                    </Grid>
                    {this.displayableYetToRespond(attendance.yetToRespond)}
                </Grid>}
                <Chip icon={<CelebrationIcon />} id="majorityWinner2" label={"Majority Winner: " + displayableMajorityWinner + " Count: " + attendance.whoCanMakeIt.length} />
            </Stack>
        )
    };

    getProgressBar = () => {
        const progress = (this.getNumberOfMembersCompleted() / this.state.groupData.groupMembers.length) * 100;
        return <BorderLinearProgress variant="determinate" value={progress} />
    };

    getGroupMembers = () => {
        let members = [];
        for (var i = 0 ; i < this.state.groupData.groupMembers.length ; i++) {
            members.push(<MenuItem value={this.state.groupData.groupMembers[i].name}>{this.state.groupData.groupMembers[i].name}</MenuItem>);
        }
        return members;
    };

    handleGroupMemberChange = (event) => {
        this.showSubmitErrorMessage(false);
        this.setState({showCommonDates: false, currentUserSelected: event.target.value});
    };

    fetchPageData = () => {
        loadGroupData(this.props.groupID).then((groupData) => {
            this.setState({groupData: groupData, fetchingData: false, currentUserSelected: groupData.groupMembers[0].name});
        });
    };

    submitAvailability = () => {
        if(!this.state.currentUserSelected){
            this.showSubmitErrorMessage(true);
            return;
        }
        appendAvailability(this.props.groupID, {groupMembers: this.state.groupData.groupMembers});
    };

    showSubmitErrorMessage = (showErrorMessage) => {
        if (showErrorMessage) {
            document.getElementById("submitAvailabilityError").textContent = "You must select a group member before submitting.";
            return;
        } else {
            document.getElementById("submitAvailabilityError").textContent = "";
            return;
        }
    };

    onDateChange = (dates) => {
        // Update directly on current user's fetched data
        const currentGroupMember = this.state.groupData.groupMembers.filter(member => member.name == this.state.currentUserSelected);
        currentGroupMember[0].availability = dates; 
        this.forceUpdate();
    }

    getCommonAvailability = () => {
        const availabilities = [];
        for (var i = 0; i < this.state.groupData.groupMembers.length; i++) {
            availabilities.push(this.state.groupData.groupMembers[i].availability);
        }
        return availabilities?.reduce((acc, currentValue) => {
            return acc.filter(res => currentValue.find(value => value === res))
          })
    };

    getNumberOfMembersCompleted = () => {
        var completed = 0;
        for (var i = 0; i < this.state.groupData.groupMembers.length; i++) {
            if (this.state.groupData.groupMembers[i].availability[0] !== undefined) {
                completed = completed + 1;
            }
        }
        return completed;
    };

    showHideCommonDates = () => {
        this.setState({showCommonDates: !this.state.showCommonDates});
        this.getAttendance();
    };

    getBestDateForMajority = () => {
        var dict = {};
        
        if (this.state.groupData.groupMembers.length == 0 || this.getNumberOfMembersCompleted() == 0) {
            return;
        }
        for (var i = 0; i < this.state.groupData.groupMembers.length; i++) {
            const dates = this.state.groupData.groupMembers[i].availability;
            for(var j = 0; j < dates.length; j++) {
                if (dict[dates[j]]) {
                    dict[dates[j]] = {date: dates[j], value: dict[dates[j]].value + 1};
                } else {
                    dict[dates[j]] = {date: dates[j], value: 1};
                }
            }
        }

        if (dict === {}) {
            return;
        }

        const majorityWinner = Object.keys(dict).reduce((a, b) => dict[a].value > dict[b].value ? a : b);
        return dict[majorityWinner].date;
    };

    getAttendance = () => {
        const date = this.getBestDateForMajority();
        let whoCanMakeIt = [];
        let whoCannotMakeIt = [];
        let yetToRespond = [];
        for (var i = 0; i < this.state.groupData.groupMembers.length; i++) {
            const dates = this.state.groupData.groupMembers[i].availability;
            if (dates.length === 0) {
                yetToRespond.push(this.state.groupData.groupMembers[i].name);
                continue;
            }

            var canAttend = false;           
            for(var j = 0; j < dates.length; j++) {
                if (dates[j] == date) {
                    canAttend = true;
                    break;
                }
            }
            if(canAttend) {
                whoCanMakeIt.push(this.state.groupData.groupMembers[i].name);
            } else {
                whoCannotMakeIt.push(this.state.groupData.groupMembers[i].name);
            }
        }
        return {whoCanMakeIt: whoCanMakeIt, whoCannotMakeIt: whoCannotMakeIt, yetToRespond: yetToRespond};
    };

    displayableWhoCanMakeIt = (whoCanMakeIt) => {
        let rows = [];
        for (var i = 0; i < whoCanMakeIt.length; i++) {
            rows.push(
                <Grid xs={4} sm={4} md={3} lg={3} margin={"1%"} textAlign={"center"}>
                    <Chip icon={<FaceIcon />} label={whoCanMakeIt[i]} />
                </Grid>
            );
        }
        return rows;
    };

    displayableWhoCannotMakeIt = (whoCannotMakeIt) => {
        let rows = [];
        for (var i = 0; i < whoCannotMakeIt.length; i++) {
            rows.push(
                <Grid xs={4} sm={4} md={3} lg={3} margin={"1%"} textAlign={"center"}>
                    <Chip icon={<FaceIcon />} label={whoCannotMakeIt[i]} />
                </Grid>
            );
        }
        return rows;
    };
    
    displayableYetToRespond = (yetToRespond) => {
        let rows = [];
        for (var i = 0; i < yetToRespond.length; i++) {
            rows.push(
                <Grid xs={4} sm={4} md={3} lg={3} margin={"1%"} textAlign={"center"}>
                    <Chip icon={<FaceIcon />} label={yetToRespond[i]} />
                </Grid>
            );
        }
        return rows;
    };

    getDateStringFromTimestamp = (timestamp) => {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth()+1;
        let date = dateObject.getDate();
        return dateObject.getFullYear() + '-' + (month < 10 ? '0'+month : month) + '-' + (date < 10 ? '0'+date : date);
    }
}