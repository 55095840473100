import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, updateDoc } from "firebase/firestore";
import { collection, addDoc, getDoc, doc } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBETg-MgI-b2REtMXYJKHbzn_tNTdQY_YA",
  authDomain: "availableornot-ccf74.firebaseapp.com",
  projectId: "availableornot-ccf74",
  storageBucket: "availableornot-ccf74.appspot.com",
  messagingSenderId: "716961458673",
  appId: "1:716961458673:web:f91aa09be561f00a667c50",
  measurementId: "G-HH8X7ZM035"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const saveGroupToDB = async (groupName, groupPIN, groupMembers) => {   
    try {
        const docRef = await addDoc(collection(db, "groups"), {
          groupName: groupName,
          groupPIN: groupPIN,
          groupMembers: groupMembers    
        });
        return docRef.id;
      } catch (e) {
        return null;
      }
}

export const validateGroupAndPIN = async (groupID, groupPIN) => {
    const docRef = await getDoc(doc(db, 'groups', groupID));
    if(docRef.exists()) {
        if(docRef.data().groupPIN == groupPIN) {
            return docRef.id;
        }
        return "PINInvalid";
    }
    else {
        return "GroupIDInvalid";
    }
}

export const loadGroupData = async (groupID) => {
    const docRef = await getDoc(doc(db, 'groups', groupID));
    if(docRef.exists()) {
        const docData = docRef.data();
        return {
            groupName: docData.groupName,
            groupMembers: docData.groupMembers
        };
    }
}

export const appendAvailability = async (groupID, toAppend) => {
    const docRef = doc(db, 'groups', groupID);
    await updateDoc(docRef, toAppend);
}